import packageJson from "../package.json";
import { PRO_URL } from "./env";

export const DEBUG = process.env.NODE_ENV === "development";
export const DEV_URL = "http://localhost:8020";
export const API_ROOT = DEBUG ? DEV_URL : PRO_URL;
export const VERSION = packageJson.version;
export const YEAR = () => {
	return new Date().getFullYear();
};
export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const timeOut = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
